.form-section{
  max-width: 580px !important;
  margin: auto;
}

.form-feedback {
  width: 100%;

  .form-group {
    width: 100%;
    background-color: #fff;
    border: 2px solid #fff;
    padding: 26px 30px;
    margin-bottom: lineHeight();
    position: relative;

    &:hover{
      border: 2px solid $bg-dark;
    }

    &.error{

      &:hover{
        border: 2px solid $error;
      }

    }


    input{
      margin: 0;
      padding: 0;
      height: lineHeight();
      color: $text-dark;
      font-size: 18px;

      &:focus {
        outline: none;
      }

    }

    textarea{
      margin: 0;
      padding: 0;
      resize: none;
      color: $text-dark;
      font-size: 18px;

      &:focus {
        outline: none;
      }

    }

    label {
      color: $text-dark;
      font-size: 18px;
      position: absolute;
      pointer-events: none;
      left: 30px;
      top: 27px;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }
    
    /* active state */
    input:focus ~ label,
    input:valid ~ label,
    textarea:focus ~ label,
    textarea:valid ~ label {
      top: 3px;
      font-size: 14px;
      color: $text-light;
    }

    span {
      position: absolute;
      left: 30px;
      bottom: 5px;
      font-size: 0.75rem;
    }

  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    width: 100%;
    padding: 26px 30px;
    margin-bottom: lineHeight();
    color: #000000;
    display: inline-block;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.41);
    mix-blend-mode: normal;
    border: 1px dashed #085DB6;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background: rgba(255, 255, 255, 1);
  }

  .inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .inputfile + label * {
    pointer-events: none;
  }

  .checkbox{
    margin-bottom: lineHeight();
  }

  .checkbox input[type="checkbox"] {
    opacity: 0;
    margin: 0;
    position: absolute;
  }

  .checkbox label {
      position: relative;
      display: inline-block;
      font-size: 0.95rem;
      line-height: 1.2;
      /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
      padding-left: 34px;
      padding-top: 3px;
  }

  .checkbox label::before,
  .checkbox label::after {
      position: absolute;
      content: "";
      
      /*Needed for the line-height to take effect*/
      display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  .checkbox label::before{
      height: 16px;
      width: 16px;
      background-color: #ffffff;
      border: 0px solid;
      left: 0px;
      
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
      *to vertically center it.
      */
      top: 3px;
  }

  /*Checkmark of the fake checkbox*/
  .checkbox label::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid $bg-dark;
      border-bottom: 2px solid $bg-dark;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
  }

  /*Hide the checkmark by default*/
  .checkbox input[type="checkbox"] + label::after {
      content: none;
  }

  /*Unhide on the checked state*/
  .checkbox input[type="checkbox"]:checked + label::after {
      content: "";
  }

  /*Adding focus styles on the outer-box of the fake checkbox*/
  .checkbox input[type="checkbox"]:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
  }

  .captcha{
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: lineHeight();

    @include breakpoint ($xl){
      width: auto;
      float: left;
    }

  }

  .submit{
    height: 60px;
    margin: 8px auto lineHeight() auto;
    float: none;

    @include breakpoint ($xl){
      float: right;
      
      margin: 8px 0 lineHeight() 0;

    }

  }

  .form-control {
    width: 100%;

  }

  form {
    width: 100%;
  }
}


