.accordion{
    max-width: 780px;
    margin-bottom: 4rem;

    .accItem{
        border-top: 1px solid $line;
        
        &:last-child{
            border-bottom: 1px solid $line;
        }      
        
        .accHeader{
            padding: 20px 40px 20px 0;
            cursor: pointer;
            position: relative;

            h2{
                text-align: left;
                height: 39px;
                line-height: 1;
                margin: 0;
                font-size: 1.2rem;
                font-weight: bold;
                color: $text-dark;
                vertical-align: middle;
                display: table-cell;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .accButton{
                position: absolute;
                top: 20px;
                right: 0;
                width: 39px;
                height: 39px;
                border: 1px solid $bg-dark;
                color: $bg-dark;
                text-align: center;
                line-height: 39px;
                box-sizing: border-box;
                border-radius: 5px;
                font-size: 26px;

                &::after{
                    content: "+";
                }

            }

            &.active{

                h2{
                    color: $bg-dark;
                }

                .accButton{
                    color: #ffffff;
                    background-color: $bg-dark;

                    &::after{
                        content: "-";
                    }
                }

            }

        }

        .accCont{
            display: none;
        }        

    }

}