﻿.d-none {
  display: none !important; // sass-lint:disable-line no-important
}

.p-none {
  padding: 0;
}

.m-none {
  margin: 0;
}

.text {
  &-left {text-align: left;}
  &-center {text-align: center;}
  &-right {text-align: right;}
}

.full-width {
  max-width: none;
  width: 100%;
}

.bg-light {
  background-color: $bg-light;
}

.bg-dark {
  background-color: $bg-dark;
  color: white;
}

.bg-content {
  background-image: url('/images/bg_content.jpg');
  background-repeat: repeat;
}

.padding-3 {
  padding: 3em 0 !important;  // sass-lint:disable-line no-important
}
