.product {
  margin-top: 0;

  // Page title styles
  &-catalog-title {
    width: 100%;
    min-height: 42px;
    margin-bottom: lineHeight();
    position: relative;

    .title {
      display: none;
    }

    .button-product-nav {
      height: 42px;
      margin: 0;
      padding: lineHeight()/2;
      background-color: $bg;
      color: $text;
      border-color: $line;
      font-weight: 500;

      &:hover,
      &:focus {
        color: $button;
        border-color: $button;
      }
    }

    @include breakpoint($md) {
      .title {
        display: block;
        padding: 0 140px;
      }
    }
  }

  &-catalog {
    display: block;
    position: absolute;
    top: 16px;
    left: 16px;
    width: 100px;
    font-size: 13px;
    padding: lineHeight(0)/2 0;
  }

  &-nav {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 140px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .button-product-nav {
      width: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      right: auto;

      &+.button-product-nav {
        left: auto;
        right: 0;
      }
    }

    .arrow {
      &-left {
        @include arrow($width: 24px, $arrowhead: 6px);
      }

      &-right {
        @include arrow('', $button, 24px, 6px);
      }

      &-text {
        width: 100px;
        font-size: 13px;
        display: none;
      }
    }

    @include breakpoint($lg) {
      width: calc(100% - 32px);

      .button-product-nav {
        width: 180px;
      }

      .arrow-text {
        display: block;
      }
    }
  }

  // Product information styles
  &-title {
    margin: lineHeight() auto;
  }

  // All product page adaptive styles
  @include breakpoint($sm) {
    &-catalog {
      width: 140px;
      font-size: 14px;
      padding: lineHeight(0)/2;
    }
  }

  @include breakpoint($lg) {
    
    &-catalog {
      display: none;
    }
  }
}
