// Colors
$bg: #fff;
$bg-light: #ebf2f9;
$bg-dark: #085db6;

$text-date: #cdcdcd;
$text-light: #979797;
$text: #222;
$text-dark: #000;

$error: #ff5353;
$holder: #202020;
$line: #cfdff1;
$border: #d2e7fd;

$active: #2e80dd;
$button: #1476dc;

$button-gradient: linear-gradient(to top, $bg-dark, $button);
$bg-gradient: linear-gradient(to right, rgba($bg-dark, 1), rgba($button, 1));

// Fonts
$font-base: 'DINNext', sans-serif;

$base-font-size: 1rem;
$base-line-height: 1.5rem;

$type-scale: (
  -1: $base-font-size * 0.875,  // small text (14px)
  0: $base-font-size * 1,  // body text (16px)
  1: $base-font-size * 1.25,  // large text p (20px)
  2: $base-font-size * 1.56,  // h3 (24px)
  3: $base-font-size * 1.95,   // h2 (30px)
  4: $base-font-size * 2.44   // h1 (38px)
);
$line-height: (
  -1: $base-line-height * 0.5,
  0: $base-line-height * 1,
  1: $base-line-height * 1,
  2: $base-line-height * 1.5,
  3: $base-line-height * 1.5,
  4: $base-line-height * 2
);

@function lineHeight($level: 0) {
  @return map-get($line-height, $level);
}
@function typeScale($level: 0) {
  @return map-get($type-scale, $level);
}

// Breakpoints
$xs: 360px;
$sm: 480px;
$md: 768px;
$lg: 960px;
$xl: 1200px;

// Libraries
%flexwrap {
  display: flex;
  flex-wrap: wrap;
}

%clearfix {
  &::before,
  &::after {
    display: table;
    content: '';
    line-height: 0;
  }

  &::after {
    clear: both;
  }
}

%menu-li {
  li {
    float: left;
    text-align: center;

    a:hover,
    a:focus {
      text-decoration: none;
    }
  }
}




