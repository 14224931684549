.solution {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &-links {
    margin: lineHeight() 0;
    padding: 0;

    li {
      text-align: left;
      margin: 0 auto lineHeight()/3;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      display: inline-block;
      position: relative;
      text-align: left;
      font-weight: bold;
      font-size: typeScale(-1);
      line-height: 1rem;

      &:visited {
        color: $text;
      }

      &:hover,
      &:focus {
        color: $bg-dark;
      }
    }
  }

  &-title {
    color: $bg-dark;
  }

  &-text {
    font-size: typeScale(-1);
    text-align: justify;
  }

  &-info {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  &-img {
    width: 100%;
    margin: lineHeight() auto;
    text-align: center;

    img {
      display: inline-block;
      background-color: $bg;
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
      box-shadow: 0 1px 24px rgba($bg-dark, 0.5);
    }
  }

  @include breakpoint($md) {
    &-title,
    &-text,
    &-links li,
    &-links a {
      text-align: left;
    }

    &-info {
      width: 50%;
      margin: lineHeight(0) auto;
      text-align: left;
    }

    &-img {
      width: 50%;
      margin: 2*lineHeight(0) auto;
      text-align: left;

      img {
        width: 21rem;
        height: 21rem;
        box-shadow: 0 2px 48px rgba($bg-dark, 0.5);
      }
    }
  }

  @include breakpoint($lg) {
    &-img img {
      width: 27rem;
      height: 27rem;
    }

    &-text,
    &-links a {
      font-size: typeScale(0);
    }

    &-text {
      text-align: justify;
    }
  }

  @include breakpoint($xl) {
    &-img img {
      width: 30rem;
      height: 30rem;
    }
  }
}
