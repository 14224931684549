.client-item {
  @include gallery(1);
  margin: lineHeight() auto !important;
  max-width: 390px;

  &-img {
    display: block;
    height: 9rem;
    width: 100%;
  }

  &-title {
    width: 100%;
    height: auto;
    padding-right: 0;
    margin-top: lineHeight();
    text-align: center;
    font: {
      size: typeScale(1);
      weight: bold;
    }
  }

  &-info {
    font-size: typeScale(-1);
    text-align: justify;

    p {
      font-size: typeScale(-1);
      text-align: justify;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include breakpoint($md) {
    @include gallery(2);
    max-width: none;

    &-title {
      height: 3rem;
      margin-bottom: lineHeight(0)/2;
      text-align: left;
      letter-spacing: -1px;
      }

    &-info {
      font-size: typeScale(0);
      text-align: justify;

      p {
        font-size: typeScale(0);
        text-align: justify;
      }
    }
  }

  @include breakpoint($lg) {
    &-img {
      height: 12rem;
    }
  }

  @include breakpoint($xl) {
    &-title {
      font-size: typeScale(2);
      }
  }
}