﻿@include placeholder {
  font-size: typeScale(-1);
  color: $bg-dark;
  opacity: 1;
}

button {
  cursor: pointer;
  transition: all 0.5s;
}

button,
input,
textarea,
select {
  outline: none;
  border: 0;

  &:active {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

button,
input,
textarea {
  display: inline-block;
  margin-bottom: lineHeight();
  text-align: left;

  transition: all 0.5s;
}

input[type='text'],
textarea {
  padding: lineHeight(0) / 2;

  &:focus {

    transition: all 0.3s;
  }
}

input[type='submit'] {
  cursor: pointer;
  display: block;
  margin: lineHeight() auto;
  padding: lineHeight() / 2;
  background-color: $button;
  border-radius: 3px;
  border: 1px solid $button;
  color: white;

  &:hover,
  &:focus {
    background-color: $button;
    border-color: $button;
    color: white;
  }
}
