﻿// Main Styles

// sass-lint:disable no-vendor-prefixes
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &::before,
  &::after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}
// sass-lint:unable no-vendor-prefixes

:focus {
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  @include typeSetting(0);

  font-family: $font-base;
  background: $bg;
  color: $text;
}

img {
  max-width: 100%;
  height: auto;
}

.cover-img {
  @include object-fit(cover, center); // sass-lint:disable-line mixin-name-format
  width: 100%;
  height: 100%;
  max-width: none;
}

.cover-img-top {
  @include object-fit(cover, top); // sass-lint:disable-line mixin-name-format
  width: 100%;
  height: 100%;
  max-width: none;
}

.contain-img {
  @include object-fit(contain, center); // sass-lint:disable-line mixin-name-format
  width: 100%;
  height: 100%;
  max-width: none;
}

.padding-img {
  @include object-fit(contain, center); // sass-lint:disable-line mixin-name-format
  width: 100%;
  height: 100%;
  max-width: none;
  display: block;
  transform: scale(0.9);
}

.overlay {
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($bg-dark, 0.4);
    z-index: 99;
  }
}

a.svg {
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.wrapper,
.footer,
.header,
.page {
  @extend %clearfix;

  width: 100%;
  margin: 0 auto;
  padding: 0;

  &-narrow {
    @extend %clearfix;

    width: 100%;
    padding: 0 1rem;

    @include breakpoint($xl) {
      max-width: $xl;
      margin: 0 auto;
    }
  }
}

.main {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.scrollable {
  overflow-x: auto;
}

address {
  font-style: normal;
}

#map { // sass-lint:disable-line no-ids
  width: 100%;
  height: 320px;

  iframe {
    height: 100%;
  }

  @include breakpoint ($md) {
    height: 480px;
  }
}

.page {
  @extend %clearfix;

  width: 100%;
  margin: 0 auto;
}

.title {
  position: relative;
  margin: lineHeight() auto;
  font-weight: bold;
  font-size: typeScale(3);

  @include breakpoint($lg) {
    font-size: typeScale(4);
  }
}
.error {
  color: $error;
}

.arrow {
  @include arrow('right', $bg-dark, 10px, 6px);

  display: inline-block;
  height: 2px;
  bottom: 3px;
  right: -5px;

  &::before {
    margin-top: -2px;
    border-left-width: 2px;
    border-top-width: 2px;
  }
}

#totop {
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid $bg-dark;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 5px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: background-color .3s,opacity .5s,visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid $bg-dark;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -8px;
    transform: rotate(45deg);
    border-bottom: 0;
    border-right: 0;
  }

  &:hover{
    background-color: $bg-dark;

    &::before {
      border: 1px solid #ffffff;
      border-bottom: 0;
      border-right: 0;
    }
  }



  &.show {
    opacity: 1;
    visibility: visible;
  }

}

a[id]{
  position: relative;
  &::before{
    height: 100px;
    width: 1px;
    content: '';
    position: absolute;
    bottom: 0;
  }

}
