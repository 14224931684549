.catalog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &-sort {
    width: 49%;
  }

  &-filters {
    width: 49%;
  }

  .gallery {
    width: 100%;
    margin-left: 0;
  }

  @include breakpoint($lg) {

    &-sort {
      width: 100%;
    }

    &-filters {
      width: 20%;
      background-color: $bg-light;
    }

    .gallery {
      width: 78%;
      margin-left: 2%;
    }
  }
}

.catalog-item {
  @include gallery(1, 0);

  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-bottom: lineHeight();

  &-img {
    display: block;
    width: 100%;
    height: lineHeight()*8;
    padding: lineHeight()/2;
    line-height: 0;
    border: 1px solid $line;

    img {
      height: lineHeight()*7;
    }
  }

  %content {
    display: block;
    width: 100%;
    height: 3rem;
    min-height: 3rem;
    overflow: hidden;
    margin-bottom: lineHeight()/2;
    color: $text;
  }

  &-title {
    @extend %content;

    font-size: typeScale(0);
    font-weight: bold;

    &:hover,
    &:focus {
      color: $active;
    }
  }

  &-text {
    @extend %content;

    font-size: typeScale(-1);
    line-height: 1rem;
  }

  &-price {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 2rem;
    color: $text;
    font-size: typeScale(-1);

    p {
      align-self: flex-end;
    }
  }

  &-rate {
    width: 50%;
    color: $active;
    font-weight: bold;
    line-height: 1rem;
  }

  &-on,
  &-off {
    width: 50%;
    color: #1faa1f;
    font-size: 13px;
    line-height: 1rem;
    text-align: right;
  }

  &-off {
    color: $text-light;
  }

  &-info {
    display: block;
    width: 100%;
    padding: lineHeight()/2;
    border-left: 1px solid $line;
    border-right: 1px solid $line;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .button {
    width: 50%;
    font-size: 13px;
    margin: 0;
  }

  .catalog-link {
    background-color: $line;
    color: $button;
    border-color: $line;
    font-weight: normal;

    &:hover,
    &:focus {
      background-color: #468dff;
      border-color: #468dff;
      color: $bg;
    }
  }

  .button-group-2 {
    align-self: flex-end;
  }

  @include breakpoint('540px') {
    @include gallery(2);
  }

  @include breakpoint('800px') {
    @include gallery(3);
  }
}
