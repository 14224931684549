﻿// Menu stiles
.navigation {
  display: block;
  width: 100%;
  padding: 0;
  
  @include  breakpoint($lg) {
    display: block;
    padding: 1rem;
  }
}

// Top menu
.menu-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 0;

  li {
    list-style-type: none;
    width: 100%;

    &:not(:last-of-type),
    &:not(.submenu) {
      border-bottom: 1px solid rgba($text-light, 0.2);
    }
  }

  a {
    width: 100%;
    display: inline-block;
    position: relative;
    color: $text;
    padding: 1rem;
    margin: 0;
    line-height: 1rem;
    text-align: left;
    font-size: typeScale(-1);
    letter-spacing: 0;
    font-weight: bold;
    transition: 0.5s;

    &::before {
      content: none;
    }

    &:hover,
    &:focus {
      color: $button;
    }
  }

  .active>a {
    color: $button;
  }

  .submenu {
    position: relative;
    z-index: 20;

    ul {
      display: none;
      padding-inline-start: 0;
      position: relative;
      left: 0;
      top: 0;
      border: 0;
      background-color: rgba($text-light, 0.1);
      z-index: 10;
    }

    li {
      text-align: left;

      &:last-of-type {border-bottom: 0;}

      a {
        width: 100%;
        min-width: 180px;
        font-weight: normal;
        text-align: left;
        margin: 0;

        &::before {
          content: none;
        }

        &:hover,
        &:focus {
          background-color: rgba($button, 0.1);
        }
      }
    }

    li:not(:last-of-type) {
      border-bottom: 1px solid white;
    }
  }

  .submenu > a {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 20px;
      right: 1rem;
      width: 7px;
      height: 4px;
      margin-left: 4px;
      border-top: solid 4px $bg-dark;
      border-left: solid 4px transparent;
      border-right: solid 4px transparent;
    }
  }

  @include breakpoint ($lg) {
    flex-wrap: nowrap;

    li {
      width: auto;
      border-bottom: 0;

      &:not(:last-of-type),
      &:not(.submenu) {
        border-bottom: 0;
      }
    }

    a {
      width: auto;
      margin: 0.5rem;
      padding: 0;
      text-align: center;
      font-size: 13px;
      letter-spacing: -0.5px;

      &::before {
        content: '';
        background-color: $active;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        transform: scaleX(0);
        transition: 0.5s;
      }

      &:hover,
      &:focus {
        color: $button;

        &::before {
          transform: scaleX(1);
          transition: 0.3s;
        }
      }
    }

    .active>a {
      color: $button;

      &::before {
        transform: scaleX(1);
        transition: 0.3s;
      }
    }

    .submenu {

      ul {
        position: absolute;
        background-color: white;
        left: -9px;
        top: 52px;
        border: 1px solid $border;
      }

      li a {
        padding: 0.5rem 1rem;
      }

      li:not(:last-of-type) {
        border-bottom: 1px solid $border;
      }
    }

    .submenu > a::after {
      top: 6px;
      right: auto;
    }
  }

  @include breakpoint ($xl) {
    a {
      font-size: typeScale(-1);
      letter-spacing: 0;
    }

    .submenu > a {
      &::after {
        right: -12px;
      }
    }
  }
}

// Language
.menu-lang {
  position: absolute;
  z-index: 5;
  width: calc(100% + 2px);
  left: -1px;

  display: none;

  li {
    border: 1px solid $line;
    background-color: $bg;

    &:not(:last-of-type) {
      border-bottom: 0;
    }
  }

  .active {
    display: none;
  }
}

// Select language
.lang {

  li {
    list-style: none;
  }

  a {
    display: inline-block;
    width: 100%;
    padding: 3px lineHeight()/2;
    font-size: typeScale(-1);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    transition: 0.3s;
  }

  &-select {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: lineHeight(0)*2;
    padding: lineHeight(0)/2;
    color: $text;
    font-size: typeScale(0);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;
    margin: 0;
    left: 2px;

    &::after {
      content: '';
      width: 7px;
      height: 4px;
      margin-left: 4px;
      border-top: solid 4px $bg-dark;
      border-left: solid 4px transparent;
      border-right: solid 4px transparent;
    }
  }

  &-nav {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    padding-bottom: 0;
    z-index: 10;

    li {
      width: 100%;
    }

    a,
    a:visited {
      padding: lineHeight()/2 0;
      color: $text;
      border-top: 1px solid $border;
    }

    a:hover,
    a:focus {
      color: $bg-dark;
      text-decoration: none;
    }

    .active,
    .active:visited {
      display: none;
      padding: 0;
    }
  }
}

// News, Articles, Activity navigation
.news-nav {
  margin: lineHeight() auto;

  .title {
    margin: 0;
    display: inline-block;
  }

  li {
    margin-bottom: lineHeight();
    padding: 0;
  }

  a {
    display: inline-block;
    position: relative;
    color: #979797;
    font: {
      size: typeScale(3);
      weight: normal;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -6px;
      left: 0;
      border-top: 1px dashed $bg-dark;
    }

    &:visited {
      color: #979797;
    }

    &:hover,
    &:focus {
      color: $bg-dark;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    
    @include breakpoint (600px) {
      flex-direction: row;

      li {
        padding: 0 lineHeight(0)/2;
      }
    }
  }
}
