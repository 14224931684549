.error404 {
  text-align: center;
  padding: 4rem 1rem;

  .erTitle {
    font-size: 8rem;
    line-height: 8rem;
    font-weight: 900;
    padding-bottom: 1.5rem;
    color: $bg-dark;

    @include breakpoint($sm) {
      font-size: 12rem;
      line-height: 12rem;
    }
  }

  p {
    text-align: center;
    font-size: typeScale(0);
    color: $text;

    @include breakpoint($sm) {
      font-size: typeScale(1);
    }
  }
}
