.subscribe {
  width: 100%;
  padding-top: lineHeight();
  border-top: 1px solid $line;

  #mc_embed_signup {
    padding: 0;

    input.email {
      font-family: $font-base;
      line-height: 1rem;
      font-size: 14px;
      border: 1px solid $bg-dark;
      border-radius: 4px;
      color: $bg-dark;
      background-color: #fff;
      height: 40px;
      padding: lineHeight(0) / 2;
      display: inline-block;
      margin: 0 0 0.75rem;
      width: 100%;

      &:focus {
        border-width: 2px;
      }
    }

    .button {
      font-size: 14px;
      border: 0;
      border-radius: 4px;
      letter-spacing: 0.03em;
      color: #fff;
      background-color: $bg-dark;
      height: auto;
      width: 160px;
      line-height: 1rem;
      padding: lineHeight(0)/2;
      display: inline-block;
      margin: 0;
      transition: all 0.3s ease-in-out 0s;
    }
  }

  @include breakpoint($sm) {
    
    #mc_embed_signup {
      /*padding: 0 lineHeight(0);*/

      .clear {
        display: inline-block;
        width: auto;
      }

      input.email {
        width: calc(100% - 176px);
        margin-right: 12px;
        margin-bottom: 0;
      }

      form {
        padding: 10px 0 0;
      }
    }
  }
  @include breakpoint($md) {

    #mc_embed_signup {
      /*padding: 0 lineHeight(0);*/

      input.email {
        width: calc(100% - 176px);
        margin-right: 12px;
        margin-bottom: 0.75rem;
      }
    }
  }
}
