﻿$num: $text;
$num-active: white;
$bg-num: white;
$bg-num-active: $bg-dark;
$border-num: #e6eef8;

// Pagination
.pagination {
  @extend %menu-li;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;

  li {
    display: inline-block;
    float: none;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;

    &:first-of-type{
      display: none;
    }

    &:last-of-type{
      display: none;
    }

  }

  a,
  a:visited,
  span {
    display: table-cell;
    height: lineHeight()*2;
    width: lineHeight()*2;
    line-height: lineHeight()*2;
    text-align: center;
    font-size: typeScale(-1);
    vertical-align: middle;
    background-color: $bg-num;
    color: $num;
    border: 1px solid $border-num;
    border-radius: 4px;
  }

  a:hover,
  a:focus {
    text-decoration: none;
    color: $bg-num-active;
    border-color: $bg-num-active;
    transition: all 0.5s;
  }

  .active > a {
    font-size: typeScale(0);
    text-decoration: none;
    color: $num-active;
    background-color: $bg-num-active;
    border: 0;
    transition: all 0.5s;
  }

  .dotted > span {
    font-size: typeScale(0);
    padding-bottom: 8px;
    width: lineHeight();
    background-color: transparent;
    color: $bg-num-active;
    border: 0;
  }

  .prev,
  .next {

    > a,
    > span {
      position: relative;
      font-size: 0;

      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        margin: auto;
        transform: rotate(45deg);
      }
    }

    > a:hover,
    > a:focus {
      border-color: $bg-num-active;

      &::before {
        border-color: $bg-num-active;
      }
    }
  }

  .prev > a::before,
  .prev > span::before {
    border-left: 1px solid $bg-num-active;
    border-bottom: 1px solid $bg-num-active;
    top: 20px;
    left: 22px;
  }

  .next > a::before,
  .next > span::before {
    border-right: 1px solid $bg-num-active;
    border-top: 1px solid $bg-num-active;
    top: 20px;
    right: 22px;
  }

  .prev > span::before,
  .next > span::before {
    border-color: rgba($num, 0.3);
  }

  &-wrapper {
    width: 100%;
    margin: lineHeight() auto;
    text-align: center;
  }
}
