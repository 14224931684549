// Styles for search result page
.search {

  &-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin-bottom: lineHeight()*2;

    form {
      position: relative;
      width: 100%;
      padding: lineHeight();
      border-bottom: 1px solid $bg-light;
    }

    .input-search {
      border: 2px solid $text;
      color: $holder;
      height: 40px;

      &:hover,
      &:focus {
        border-color: $active;
        color: $text;

        &+.icon-search {
          color: $active;
        }
      }
    }

    button.icon-search {
      color: $button;
      right: 26px;
      top: 26px;

      svg {
        margin-bottom: 0;
        margin-left: 2px;
      }
    }

    @include breakpoint($md) {
      padding: lineHeight(0) lineHeight(0)/2;
    }

    @include breakpoint($lg) {
      padding: lineHeight(0);
    }
  }
}

.sisea-result h3 {
  margin-bottom: 0;
  text-align: left;
}

.sisea-result a, .sisea-result a:focus, .sisea-result a:visited {
  color: $text-dark;
  text-decoration: none;
  font-size: 1.2rem;

  &:hover{
    color: $text-light;
  }

}

.sisea-paging {
  display: table;
  text-align: center;
  vertical-align: middle;
  width: auto;
  margin: lineHeight(0) auto lineHeight(0);

  .simplesearch-page{
    display: table-cell;
    height: 3rem;
    width: 3rem;
    text-align: center;
    font-size: 0.875rem;
    vertical-align: middle;
    background-color: white;
    color: #222;
    border: 1px solid #e6eef8;
    border-radius: 4px;

    &.simplesearch-current-page{
      background-color: $bg-dark;
      color: #ffffff;
    }

    a{
      display: table-cell;
      transition: all 0.5s;
      border-radius: 4px;
      height: 3rem;
      width: 3rem;
      vertical-align: middle;

      &:hover{
        background-color: $bg-dark;
        color: #ffffff;
      }

    }

  }

}

.simplesearch-highlight{
  text-shadow: -3px 0 3px #ff0, 3px 0 3px #ff0, 6px 0 6px #ff0, -6px 0 6px #ff0;
}