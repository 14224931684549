.contact {
  @include bgSection('/images/bg_section.jpg', $bg-light);

  background-color: rgba(white, 0.7);

  .title {
    padding-bottom: lineHeight();
    text-align: left;
  }

  .form-feedback {
    @include bgSection('/images/bg_section.jpg', $line, 0.3);

    width: 100%;
    padding: 1rem;
  }

  &-page {
    @extend %flexwrap;
    justify-content: center;
  }

  &-address {
    margin-top: lineHeight(0)/2;

    p {
      padding: 0;
      margin: auto;
      text-align: center;
      font-style: normal;
      font-size: typeScale(-1);
    }

    a {
      font-weight: bold;
      font-size: typeScale(-1);
    }
  }

  &-item {
    @include gallery(1);

    margin-bottom: lineHeight(0);

    h2 {
      padding: 0;
      margin: 0;
      font-size: typeScale(0);
      color: $bg-dark;
      text-align: center;
    }
  }

  &-group {
    width: 100%;
    /*padding: 0 1rem;*/
  }

  .gallery {
    max-width: 100%;

    &+.gallery {
      padding-top: lineHeight(0)*2;
      border-top: 1px solid $line;
    }
  }

  @include breakpoint ($md) {

    &-item {
      @include gallery(2);
    }
  }

  @include breakpoint($lg) {
    background-image: linear-gradient(to right, white 0%, white 50%, transparent 50%, transparent 100%);
    background-color: rgba($bg-light, 0.7);

    &::before {
      width: 50%;
      left: 50%;
    }

    &-address p {

      text-align: left;

    }

    &-item {
      @include gallery(1);

      h2 {
        text-align: left;
      }
    }

    &-group {
      width: 50%;
      /*max-width: $xl/2;*/
    }

    &-page {
      justify-content: flex-end;
    }

    .gallery {
      max-width: 400px;
    }

    .form-feedback {
      width: 50%;
      background-color: transparent;
      padding: 0 0 0 1rem;

      @include breakpoint($xl){
        padding: 0 0 0 4rem;
      }

      &::before {
        content: none;
      }
    }
  }
}
