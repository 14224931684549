// Typography

h1,
h2,
h3,
h4 {
  font-family: $font-base;
  font-weight: bold;
  margin-top: 0;
  margin: lineHeight()/2 auto;
  text-align: center;
  line-height: lineHeight();

  @include breakpoint($lg) {
    margin: lineHeight(0) auto;
  }
}

h1 {
  font-size: typeScale(3);

  @include breakpoint($lg) {

    @include typeSetting(4);
  }

}

h2 {
  font-size: typeScale(2);

  @include breakpoint($lg) {

    @include typeSetting(3);
  }
}

h3 {
  font-size: typeScale(1);

  @include breakpoint($lg) {

    font-size: typeScale(2);
  }
}

h4 {
  @include typeSetting(1);
}

a {
  font-size: typeScale(0);
  line-height: 1.5rem;
  color: $text;
  font-family: $font-base;
  text-decoration: none;
  transition: all 0.5s;

  &:visited {
    color: $text;
  }

  &:hover,
  &:focus {
    color: $active;
    transition: all 0.5s;
  }
}

b,
strong {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

ul,
menu,
dir {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

p,
ul,
ol,
pre,
table,
blockquote {
  font-size: typeScale(0);
  line-height: 1.5rem;
  font-family: $font-base;
  margin-top: 0;
  padding-bottom: lineHeight()/2;
  text-align: left;
  margin-block-end: 0;
}

ul ul,
ol ol,
ul ol,
ol ul {
  text-align: left;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;

  list-style: none;
}

.hr,
hr {
  background-color: $line;
  border: 0;
  height: 1px;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

