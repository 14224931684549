// Dots 
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  z-index: 5;
  bottom: 1rem;
  width: 180px;
  left: 50%;
  margin-left: -90px;

  li {
    height: 12px;
    width: 12px;
    margin: 0;

    button {
      position: relative;
      display: inline-block;
      height: 6px;
      width: 6px;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:hover,
      &:focus {

        &::before {
          opacity: 1;
          background-color: $bg-dark;

          transition-property: background-color;
          transition-duration: 0.5s;
        }
      }

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        font-size: 0;
        line-height: 0;
        color: transparent;
        opacity: 0.3;
        background-color: $bg-dark;
        transform: scale(1);
      }
    }

    &.slick-active button::before {
      background-color: $bg-dark;
      color: transparent;
      opacity: 1;
      transform: scale(1.2);
    }
  }

  @include breakpoint($sm) {
    width: 260px;
    margin-left: -130px;
  }
  
  @include breakpoint($md) {
    width: 600px;
    margin-left: -300px;
  }

  @include breakpoint($lg) {
    bottom: 6rem;

    li {
      height: 16px;
      width: 16px;

      button {
        height: 8px;
        width: 8px;

        &::before {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}

// Arrows
.slider-nav {
  position: relative;
  width: 100%;
  height: 32px;
  max-width: 400px;
  margin: auto;
  padding: 0 1rem;

  @include breakpoint($md) {
    max-width: 800px;

  }

  @include breakpoint($lg) {
    max-width: $xl;
  }

  @include breakpoint(1366px) {
    max-width: 1366px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -32px;
    margin-left: (-1366px / 2);

    &+.button {
      margin-top: lineHeight()*3;
    }
  }
}

.slider-prev,
.slider-next {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 0;
  margin: 0;
  padding: 0;
  border: 1px solid $bg-dark;
  border-radius: 4px;
  outline: none;
  transition: 0.3s;

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border: 1px solid $bg-dark;
    transform: rotate(45deg);
  }

  &:hover,
  &:focus {
    outline: none;
    background: $bg-dark;
    color: transparent;

    &::before {
      border-color: white;
    }
  }
}

.slider-prev {
  left: 32px;

  &::before {
    border-top: 0;
    border-right: 0;
    margin-left: -3px;
  }
}

.slider-next {
  right: 28px;

  &::before {
    border-bottom: 0;
    border-left: 0;
    margin-left: -7px;
  }
}

// Solutions slider
.slider-project {
  @include bgSection('/images/bg_footer.jpg', $bg-light, 0.85);

  &::before {
    z-index: 0;
    opacity: 0.25;
  }

  .slick-dots {
    bottom: -1.5rem;
  }

  @media (min-width: 1366px) {
    .slick-dots {
      bottom: 0;
    }

    .slider-nav {
      margin-top: 16px;
    }
  }
}

.slide-solution {
  padding: 0 1rem;

  .solution-img>img {
    box-shadow: 0 1px 16px rgba($bg-dark, 0.5);
  }

  @include breakpoint($md) {
    
    .solution-img>img {
      box-shadow: 0 1px 18px rgba($bg-dark, 0.5);
    }
  }

  @media (min-width: 1200px) {
    padding: 0 2rem;

    .solution-img img {
      width: 27rem;
      height: 27rem;
      box-shadow: 0 1px 24px rgba($bg-dark, 0.5);
    }
  }
}

// Certificate slider
.slide-certificate {
  max-width: 320px;
  margin: lineHeight() auto !important;  // sass-lint:disable-line no-important

  &-img {
    display: block;
    position: relative;
    width: auto;
    padding: 1.5rem;
    background-color: white;
    box-shadow: 0 4px 16px rgba($button, 0.2);
    transition: 0.5s;

    &::before {
      content: '';
      position: absolute;
      background-color: $bg-dark;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: 0.3s;
    }

    img {
      display: block;
      height: 18rem;
    }

    &:hover,
    &:focus {

      .zoom {
        opacity: 1;
      }

      &::before {
        opacity: 0.2;
      }
    }
  }

  &-item {
    padding: 1rem;
  }

  .slick-dots {
    bottom: -3rem;
  }

  @include breakpoint(600px) {
    max-width: $md;

    &+.slider-nav {
      max-width: $md;
    }
  }

  @include breakpoint($lg) {
    max-width: $lg;

    .slick-dots {
      bottom: -3rem;
    }

    &+.slider-nav {
      max-width: $lg;
    }
  }

  @include breakpoint($xl) {
    max-width: $lg;
  }

  @include breakpoint(1366px) {
    &+.slider-nav {
      max-width: 1366px;
      margin-top: 0;
    }
  }
}

// Slider sections
.section-slider {
  width: 100%;
  position: relative;
  padding: lineHeight() 0;

  @include breakpoint($md) {
    padding: lineHeight(2) 0;
  }
}


.slider-doc {
  padding-bottom: lineHeight(0);

  @include breakpoint($xl) {
    padding-bottom: lineHeight(0)*2;
  }
}

#slick-main [data-animation-in] {
  opacity: 0;
}

.slick-slide img {
  height: 100%;
}