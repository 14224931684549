//Slider
.slider {
  margin-bottom: 0;
  margin-top: 0;
}

.header-slide {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 30rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: $text-date;
  margin: auto;

  .wrapper-narrow {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .button {
    width: 120px;
  }

  @include breakpoint($md) {
    height: 30rem;
  }
  @include breakpoint($lg) {
    justify-content: left;
    height: 100vh;

    .wrapper-narrow {
      flex-direction: row;
      justify-content: space-between;
    }
  
  }
}

.header-slider-button {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  margin-left: -60px;

  @include breakpoint($lg) {
    position: relative;
    left: 0;
    margin-left: 0;
  }
}

.header-slider-img {
  width: 100%;
  height: 12rem !important; // sass-lint:disable-line no-important
  max-width: 360px;
  margin: 0 auto 6.75rem;
  border: 0;

  @include breakpoint($lg) {
    height: auto !important; // sass-lint:disable-line no-important
    width: 68%;
    max-width: none;
    margin: 0;
  }
}

.header-slider-info {
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin: auto;

  p {
    color: $text;
    font-size: typeScale(-1);
    text-align: center;
    padding: 0;
    margin: lineHeight()/2 0;
    max-height: 6rem;
    overflow: hidden;
  }

  @include breakpoint($lg) {
    width: 30%;
    max-width: none;
    text-align: left;
    margin: 0;

    p {
      font-size: typeScale(0);
      text-align: left;
      margin-bottom: lineHeight();
    }
  }
}

.header-slider-title {
  @include typeSetting(2);
  text-align: center;
  color: $bg-dark;
  width: 100%;
  margin: lineHeight() auto lineHeight()/2;
  line-height: 1.5rem;

  @include breakpoint($lg) {
    font-size: 2rem;
    line-height: 2rem;
    text-align: left;
  }
}

.header-slide-wrapper {

  .slider-nav {
    opacity: 0.5;
    position: absolute;
    bottom: 0.5rem;
    width: 320px;
    left: 50%;
    margin-left: -160px;


    @include breakpoint($sm) {
      width: 480px;
      margin-left: -200px;
    }

    @include breakpoint($md) {
      width: 100%;
      left: 0;
      margin-left: auto;
    }

    @include breakpoint($lg) {
      bottom: 6rem;
    }

    @include breakpoint(1200px) {
      left: 50%;
      margin-left: -600px;
    }

    @include breakpoint(1366px) {
      margin-left: (-1366px / 2);
    }
  }

  
  &:hover,
  &:focus {
    .slider-nav {
    opacity: 1;
    }
  }
}

// About company
.plus {

  &-value {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 6rem;
    height: 6rem;
    margin: 3rem auto;
    border-radius: 50%;
    background-color: #f7fafc;
    box-shadow: 0 2px 10px rgba($bg-dark, 0.15);

    color: $bg-dark;
    font: {
      size: typeScale(3);
      weight: bold;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 6rem;
      height: 6rem;
      border: 1px solid rgba($bg-dark, 0.3);
      opacity: 0;
      border-radius: 50%;
    }

    &::before {
      animation: ripple 2s linear infinite;
    }

    &::after {
      animation: ripple 2s linear 1s infinite;
      animation-delay: 1s;
    }
  }

  &-text {
    font-size: typeScale(-1);
    line-height: 1rem;
    margin: auto;
    width: 100%;
    max-width: 280px;
  }

  &-block {
    width: 100%;
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 3rem;
  }

  @include breakpoint($sm) {
    &-block {
      width: 50%;
    }
  }

  @include breakpoint($md) {
    &-block {
      width: 30%;
    }
  }

  @include breakpoint($lg) {
    &-block {
      width: 16.5%;
    }
  }

  @include breakpoint($xl) {
    &-text {
      font-size: typeScale(0);
    }
  }
}

// News section
.slick-news {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;

  .slick-dots {
    bottom: -1.5rem;
  }

  &-item-more {
    margin-top: lineHeight()/2;

    a {
      display: flex;
      justify-content: center;
      font-weight: bold;
      color: $bg-dark;

      &:visited {
        color: $bg-dark;
      }
    }

  }

  &-item-info {
    padding: lineHeight() 0;
    border-bottom: 1px solid#f1f1f1;
    height: 212px;
    overflow: hidden;

    h3 {
      margin: 0 auto 1rem;
      padding: 0;
      text-align: center;
      line-height: 1;

      a {
        margin: 0;
        padding: 0;
        font-weight: bold;
        color: $bg-dark;

        &:visited {
          color: $bg-dark;
        }
      }
    }

    p {
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: typeScale(-1);
      text-align: justify;
    }
  }

  &-item-img {
    height: 12rem;
    overflow: hidden;

    img{
      margin: 0 auto;
    }

  }

  &-item {
    margin: lineHeight() lineHeight()/2;
    padding: 1rem;
    box-shadow: -2px 5px 15px rgba($text-date, 0.5);

    .arrow {
      margin: 0;
      bottom: -10px;
      right: -5px;
    }

    &:hover,
    &:focus {

      .slick-news-item-more {
        a {
          justify-content: center;
        }
      }

      .arrow {
        right: -5px;
        animation: back-forth 1s linear infinite;
      }
    }
  }

  @include breakpoint($md) {
    max-width: 800px;

    .arrow {
      right: 0;
    }

    &-item-more {
      a {
        justify-content: space-between;
      }
    }
  }

  @include breakpoint($lg) {
    max-width: $xl;
  }
}

// Clients and vendors
.slick-logo {

  &-item {
    display: block;
    width: auto;
    height: 7.5rem;
    padding: 1.5rem;
    background-color: white;

    img {
      display: block;
      height: 4.5rem;
    }
  }

  &-wrap {
    padding: 12px;
  }

  .slick-dots {
    bottom: -1.5rem;
  }
}


// Sections
.section {
  width: 100%;
  margin: 0 auto;
  padding: lineHeight() 0;

  &-about {
    @include bgSection('/images/bg_section.jpg');

    text-align: center;
  }

  &-news {
    position: relative;
    padding: lineHeight() 0;
    text-align: center;
  }

  &-clients,
  &-vendors {
    position: relative;
    margin-bottom: lineHeight();
    text-align: center;

    @include breakpoint (1366px) {
      .slider-nav {
        margin-top: 20px;
      }
    }
  }

  &-clients {
    @include bgSection('/images/bg_footer.jpg', $bg-light, 0.85);

    padding-bottom: lineHeight()*2;

    .slick-logo {
      margin-bottom: 1.5rem !important; // sass-lint:disable-line no-important

      .slick-dots {
        bottom: -3rem;
      }
    }

    @include breakpoint (1366px) {
      .slider-nav {
        margin-top: 0;
      }
    }

  }

  &-feedback {
    @include bgSection('/images/bg_section.jpg', $bg-light);

    margin-top: lineHeight();
  }

  &-odd-even {
    padding: 0;

    .odd-even {
      @include bgSection('/images/bg_footer.jpg', $bg-light, 0.85);

      &:nth-child(even) {
        background-color: $bg;

        &::before {
          content: none;
        }

        @include breakpoint ($md) {
          .solution {
            flex-direction: row-reverse;

            &-img {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
