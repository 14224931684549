﻿// Header styles
.header {
  @extend %clearfix;

  padding-top: lineHeight(0)*2;
  background-color: #fff;
  color: $bg-dark;

  &-home {
    height: auto;
    position: relative;
  }

  &-slide-wrapper {
    position: relative;
  }

  &-bottom-bar {
    position: relative;
  }

  .fixed-top {
    background-color: rgba(#fff, 0.9);
    height: lineHeight()*2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
  }

  .header-narrow {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: lineHeight()*2;
    justify-content: center;
    align-items: center;
  }

  .lang {
    position: absolute;
    top: 0;
    right: 2px;
    border-left: 1px solid $line;
    z-index: 10;
  }

  &-contacts {
    display: flex;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    background-color: $bg-dark;
    color: white;
    padding: lineHeight()/2;

      &:before, &:after{
        content: none;
      }

    .social-light {
      display: flex;
      flex-wrap: nowrap;
      width: 90px;
      justify-content: space-between;
    }

    .address,
    .phone {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0;
      margin-bottom: lineHeight()/2;

      svg {
        margin-top: 0;
        margin-right: 0;
      }
    }

    a,
    p {
      width: 100%;
      padding: 0;
      margin: 0;
      color: white;
      text-align: center;
    }

    a:hover,
    a:focus {
      text-decoration: underline;
    }

    @include breakpoint($md) {
      height: 64px;
      flex-wrap: nowrap;
      justify-content: space-between;

      .address,
      .phone {
        flex-wrap: nowrap;
        width: auto;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 0.7rem;
        margin-bottom: 0;

        svg {
          margin-top: 3px;
          margin-right: 8px;
        }
      }

      a,
      p {
        text-align: left;
      }
    }
  }

  &-logo {
    margin-top: 6px;
    margin-right: 56px;

    svg {
      width: 98px;
      height: 37px;
      color: $bg-dark;
    }
  }

  &-nav {
    display: block;
    width: 100%;
    max-width: 360px;
    background-color: #f4f6f6;
    position: absolute;
    top: 48px;
    left: 0;
    transform: scaleX(0);
    transform-origin: top left;
    transition: 0.5s;

    &.active {
      overflow: auto;
      transform: scaleX(1);
      transition: 0.5s;
    }

    .button {
      margin-left: 1rem;
    }
  }

  &-title {
    min-height: 6rem;
    background-color: $bg-light;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  &-slider {
    background-color: $bg-light;
    color: $text;
  }

  @include breakpoint($lg) {
    padding-top: lineHeight(0)*3;

    &-home {
      height: 100vh;
    }

    &-slide-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &-bottom-bar {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .fixed-top {
      height: lineHeight(0)*3;
    }

    .header-narrow {
      height: lineHeight(0)*3;
      justify-content: left;
    }

    .lang {
      border: 0;

      &-select {
        height: lineHeight(0)*3;
      }
    }

    &-logo {
      margin-right: 0;

      svg {
        width: 128.5px;
        height: 48px;
      }
    }

    &-nav {
      display: flex;
      align-items: center;
      width: calc(100% - 180px);
      max-width: none;
      background-color: transparent;
      transform: scaleX(1);
      position: relative;
      top: 0;

      .button {
        width: 174px;
        padding: lineHeight(0)/2;
        margin: 0;
      }
    }

    &-title {
      min-height: 12rem;
    }
  };
}

// Hamburger button
.menu-hamburger-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 30%;
  margin-top: -0.5px;
  height: 2px;
  width: 40%;
  background-color: $bg-dark;

  transition: 0.5s ease;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background-color: $bg-dark;
    position: absolute;

    transition: 0.5s ease;
  }

  &::before {
    top: -5.5px;
  }

  &::after {
    bottom: -5.5px;
  }
}

.button-mobile-menu {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: lineHeight() * 2;
  height: lineHeight() * 2;
  border-radius: 0;
  border-right: 1px solid $line;
  background-color: white;
  padding: 0;
  margin: 0;
  z-index: 10;

  &:hover {
    background-color: $bg-dark;

    .menu-hamburger-icon {
      background-color: white;
      transition: 0.5s ease;

      &::before,
      &::after {
        background-color: white;
        transition: 0.5s ease;
      }
    }
  }

  &.menu-open {
    background-color: $bg-dark;

    .menu-hamburger-icon {
      background: transparent;
      top: 50%;
      transform: translateY(-50%);

      &::before {
        background-color: white;
        top: 0;
        transform: rotate(-135deg);
      }

      &::after {
        background-color: white;
        bottom: 0;
        width: 100%;
        transform: rotate(135deg);
      }
    }
  }

  @include breakpoint($lg) {
    display: none;
  }
}

// Search button
.button-search-form {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 58px;
  width: 56px;
  height: lineHeight() * 2;
  border-radius: 0;
  border-left: 1px solid $line;
  background-color: white;
  color: $bg-dark;
  padding: 16px 19px;
  margin: 0;

  &:hover,
  &.form-open {
    background-color: $bg-dark;
    color: white;
  }

  @include breakpoint($lg) {
    display: none;
  }
}

.search {
  display: block;
  width: 260px;
  position: fixed;
  top: 48px;
  right: 20px;
  background-color: $bg-dark;
  transform: scaleY(0);
  transform-origin: top right;
  transition: 0.3s;

  &.active {
    transform: scaleY(1);
  }

  @include breakpoint($sm) {
    width: 360px;
  }

  @include breakpoint($lg) {
    position: relative;
    top: auto;
    right: auto;
    width: 25%;
    transform: scaleY(1);
  }
  @include breakpoint($xl) {
    width: 30%;
  }
}