%button {
  display: inline-block;
  margin: lineHeight() auto;
  padding: lineHeight()/2 lineHeight();
  border-radius: 4px;
  border: 1px solid;
  font-size: typeScale(-1);
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
  text-decoration: none !important;  // sass-lint:disable-line no-important

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.button {
  @extend %button;

  background-image: $button-gradient;
  border: 0;
  color: $bg;
  transition: 0.5s;

  &:visited {
    color: $bg;
  }

  &:hover,
  &:focus {
    color: $bg;
    background-image: linear-gradient(to bottom, $bg-dark, $button);
    transition: 0.5s;
  }
}

.button-light {
  background-image: none;
  background-color: $bg;
  color: $bg-dark;
  border: 2px solid $bg;

  &:hover,
  &:focus {
    background-image: none;
    background-color: $bg-dark;
    color: $bg;
  }

  &:visited {
    color: $bg-dark;
  }
}

.button-light-bordered{
  border: 2px solid $bg-dark;
}

.button-icon {
  display: flex;
  justify-content: center;

  svg {
    margin-top: -2px;
    margin-right: 6px;
  }
}

.buttons-group-2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  .button {
    width: 48%;
  }
}

.social-light > .button {
  position: relative;
  width: 40px;
  height: 40px;
  color: $bg-dark;
  background-image: none;
  background-color: white;
  border: 2px solid white;

  &:hover,
  &:focus {
    background-color: $bg-dark;
    color: white;
  }

  svg {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 50%;
    left: 50%;
    margin-top: -7px;
    margin-left: -7px;
  }
}