﻿// Page content styles

.content {
  width: 100%;
  max-width: $lg;
  margin: lineHeight() auto 0;
  padding: 0 1rem;
  text-align: center;

  h2,
  h3 {
    text-align: center;
  }

  h2 {
    @include typeSetting(2);
    color: $bg-dark;
    font-weight: bold;
  }

  p {
    text-align: justify;
  }

  li {
    padding: 0 8px;
    margin-bottom: 16px;
  }

  ol {
    list-style-type: decimal;
  }

  ul li {
    position: relative;
    padding-left: 16px;
    text-align: left;

    &::before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      left: -8px;
      top: 6px;
      background-color: $bg-dark;
    }
  }

  ol,
  ul {
    margin: lineHeight()/2 0;
    padding-inline-start: 8px;

    ol,
    ul {
      padding-inline-start: 8px;
    }
  }

  a,
  a:hover,
  a:focus,
  a:visited {
    text-decoration: underline;
  }

  a:hover,
  a:focus {

    &:not(.button) {
      color: $active;
    }
  }

  img{
    max-width: 100%;
    margin: lineHeight(2) auto;
    display: block;
  }

  @include breakpoint($md) {
    text-align: justify;

    p {
      text-align: justify;
    }
  }

  &.page{
    padding-bottom: 3rem;
  }

}
