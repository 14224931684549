// Container
@mixin container($width: 100%, $justify: auto auto, $property: max-width) {
  max-width: $width;
  margin-left: nth($justify, 1);
  margin-right: nth($justify, 2);
}

// Type settings
@mixin typeSetting($level: 0) {
  font-size: typeScale($level);
  line-height: lineHeight($level);
}

//Placeholder

// sass-lint:disable no-vendor-prefixes
@mixin placeholder {
  input::-webkit-input-placeholder { @content; }
  input:-moz-placeholder           { @content; }
  input::-moz-placeholder          { @content; }
  input:-ms-input-placeholder      { @content; }
  textarea::-webkit-input-placeholder { @content; }
  textarea:-moz-placeholder           { @content; }
  textarea::-moz-placeholder          { @content; }
  textarea:-ms-input-placeholder      { @content; }
}

//Gallery
@mixin gallery($columns: 1, $gutter: 2%) {
  $column-width: if($columns == 1, 100%, 100% / $columns - $gutter);
  $gutter-width: if($columns == 1, 0, $gutter * $columns / ($columns - 1));

  box-sizing: border-box;
  width: $column-width;

  @for $item from 1 through $columns {
    $nth: '#{$columns}n + #{$item}';

    &:nth-child(#{$nth}) {
      margin-left: $gutter-width;
    }

    &:nth-child(#{$columns}n + 1) {
      margin-left: 0;
    }
  }
}

@mixin iconCenter($width: 24px, $height: 24px) {
  width: $width;
  height: $height;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1*$width / 2;
  margin-left: -1*$height / 2;
}

// Section background cover image
@mixin bgSection($bg-img, $bg-color: $bg, $bga: 0.7) {
  position: relative;
  background-color: rgba($bg-color, $bga);

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $bg-color;
    background-image: url($bg-img);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
  }
}
// Arrow
@mixin arrow ($direction: 'left', $color: $button, $width: 30px, $arrowhead: 10px) {
  display: block;
  background: $color;
  height: 1px;
  width: $width;
  margin: 0 auto;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: $arrowhead;
    height: $arrowhead;
    top: 0;
    left: if($direction == 'left', 0, $width - $arrowhead);
    margin-top: -1*$arrowhead/2;
    border-left: 1px solid $color;
    border-top: 1px solid $color;
    transform: if($direction == 'left', rotate(-45deg), rotate(135deg));
  }
}

//  This mixin can be used to set the object-fit:
//  @include object-fit(contain);
//  or object-fit and object-position:
//  @include object-fit(cover, top);

@mixin object-fit($fit: fill, $position: null) { // sass-lint:disable-line mixin-name-format
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

// Menu mark
@mixin dropdownMark($top: 15px, $right: 0, $color: $bg, $active: $active, $size: 5px, $active-top: 18px) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: $top;
    right: $right;
    width: $size;
    height: $size;
    border-right: 1px solid $color;
    border-bottom: 1px solid $color;

    transform: rotate(45deg);
  }

  &.active {
    color: $active;

    &::after {
      border-right-color: $active;
      border-bottom-color: $active;
      top: $active-top;
      transform: rotate(-135deg);
    }
  }
}
