// Header search form
.input-search {
  margin: 0;
  width: 100%;
  color: #fff;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  height: 40px;

  &-internal-autofill-selected {
    background-color: transparent !important;
    background-image: none !important;
    color: #fff !important;
  }

  &:focus {
    border: 0;
  }

  @include breakpoint($lg) {
    border: 1px solid #4988c9;
    border-radius: 4px;

    &:focus {
      border: 2px solid #fff;
    }
  }
}

.button-search {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 2px;
  top: 2px;
  margin: 0;
  color: #fff;
  background-color: transparent;

  .arrow {
    display: block;
    background: white;

    &::before {
      border-left-color: white;
      border-top-color: white;
    }
  }

  svg {
    display: none;
  }

  @include breakpoint($lg) {
    top: 0;

    .arrow {
      display: none;
    }

    svg {
      display: block;
      margin-top: 2px;
      margin-left: 8px;
    }
  }
}

.search {
  @include placeholder {
    color: #fff;
  }

  position: relative;
  z-index: 500;
  @include breakpoint($md) {
    z-index: 1;
  }

  

  form {
    width: 100%;
  }
}
