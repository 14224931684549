﻿// Galleries
.gallery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: lineHeight();
}

// Photo-gallery
.zoom {
  @include iconCenter(32px, 32px);

  color: white;
  opacity: 0;
}

.gallery-photo {
  @include gallery(1);

  position: relative;
  overflow: hidden;
  margin-bottom: lineHeight();
  height: 15rem;
  background-color: $bg-dark;

  img {
    height: 15rem;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transform: scale(1);
    transition: 0.5s;
  }

  &:hover,
  &:focus {

    .zoom {
      opacity: 1;
    }

    img {
      top: auto;
      left: auto;
      opacity: 0.5;
      transform: scale(1.1);
    }
  }

  @include breakpoint($sm) {
    @include gallery(2);
  }

  @include breakpoint($lg) {
    @include gallery(3);
  }
}

.gallery-back {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px dashed rgba($bg-dark, 0.5);
  background-color: $bg;
  color: $bg-dark;
  font-size: typeScale(1);
  font-weight: bold;

  &:visited {
    background-color: $bg;
    color: $bg-dark;
  }

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-right: 8px;
    border-top: 1px solid $bg-dark;
    border-left: 1px solid $bg-dark;
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    background-color: $bg-dark;
    color: $bg;

    &::before {
      border-top-color: $bg;
      border-left-color: $bg;
    }
  }

  @include breakpoint($sm) {
    height: 15rem;
  }
}

// Photo-album and comments-gallery items
.gallery-item {
  @include gallery(1);

  width: 100%;
  margin-bottom: lineHeight();
  box-shadow: -2px 5px 15px rgba($text-date, 0.5);
  transition: 0.3s;

  img {
    opacity: 1;
    transition: 0.5s;
  }

  &-img,
  &-link {
    display: block;
    width: 100%;
  }

  &-img {
    height: 12rem;
    background-color: $bg-dark;
  }

  &-link {
    padding: 1rem;
    line-height: 1rem;
    text-align: center;
    font-size: typeScale();
    font-weight: bold;
    color: $button;
    background-color: white;

    &:visited {
      color: $button;
    }

    &.doc-item-title{
      text-align: left;
      position: relative;
      padding-right: 52px;

      .doc-item-link{
        position: absolute;
        width: 48px;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $bg-light;

        svg{
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -7px 0 0 -7px;
        }

      }

    }

  }

  &:hover,
  &:focus {
    box-shadow: -2px 5px 15px rgba($bg-dark, 0.2);

    img {
      opacity: 0.5;
    }
  }

  @include breakpoint($sm) {
    @include gallery(2);
  }

  @include breakpoint($lg) {
    @include gallery(3);

    &-img {
      height: 15rem;
    }
  }
}


// Photo-album and comments-gallery items
.doc-item {
  @include gallery(1);
  position: relative;
  background-color: #ffffff;

  width: 100%;
  margin-bottom: lineHeight();
  box-shadow: -2px 5px 15px rgba($text-date, 0.5);
  transition: 0.3s;

  img {
    opacity: 1;
    transition: 0.5s;
  }

  &-img,
  &-link {
    display: block;
    width: 100%;
  }

  &-img {
    height: 15rem;
    background-color: $bg-dark;

    &:hover,
    &:focus {
      box-shadow: -2px 5px 15px rgba($bg-dark, 0.2);
  
      img {
        opacity: 0.5;
      }
    }

  }

  &-link {
    padding: 0 1rem;
    margin: 1rem 0;
    line-height: 1rem;
    height: 3rem;
    display: block;
    font-size: typeScale();
    font-weight: bold;
    color: $button;
    background-color: white;
    text-align: left;
    position: relative;
    padding-right: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    cursor: pointer;

    &:visited {
      color: $button;
    }
    
    .doc-item-icon{
        position: absolute;
        width: 48px;
        top: 0;
        right: 16px;
        bottom: 0;
        background-color: $bg-light;
        transition: all 0.5s;

        svg{
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -7px 0 0 -7px;
          transition: all 0.5s;
          transform: scale(1);
        }

    }

    &:hover,
    &:focus {

      .doc-item-icon{
        color: #ffffff;
        background-color: $bg-dark;

        svg{
          transform: scale(1.5);
        }
        
      }
    }


  }

  

  @include breakpoint($sm) {
    @include gallery(2);
  }

  @include breakpoint($lg) {
    @include gallery(3);

    &-img {
      height: 15rem;
    }
  }
}
