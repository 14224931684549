// info-center, portfolio tiles styles
.case-item {
  @include gallery(1);

  display: block;
  padding: 1rem 1rem lineHeight()/2;
  margin-bottom: lineHeight();
  box-shadow: -2px 6px 18px rgba($text-date, 0.5);
  background-color: #ffffff;

  &-img {
    width: 100%;
    height: 12rem;
    margin-bottom: lineHeight();
  }

  &-title {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto 1rem;
    text-align: left;

    a {
      color: $bg-dark;
      font: {
        size: typeScale(1);
        weight: bold;
      }

      &:visited {
        color: $bg-dark;
      }
    }
  }

  &-text {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 auto lineHeight();
    text-align: justify;
    color: $text;
    font-size: typeScale(-1);

    span{
      background-color: $bg-light;
      display: inline-block;
      padding: 2px 12px;
      border-radius: 12px;
      line-height: 24px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

  }

  &-date {
    width: 50%;
    font-size: 13px;
    color: $text-date;
    text-align: left;
  }

  &-link {
    width: 50%;
    text-align: right;

    a {
      color: $bg-dark;
      margin-right: 12px;
      font: {
        size: typeScale(-1);
        weight: bold;
      }

      .arrow {
        right: -11px;
      }

      &:visited {
        color: $bg-dark;
      }
    }
  }

  &-more {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #f1f1f1;

    p {
      padding: 0;
      margin: lineHeight()/2 0 0;
    }
  }

  &-info {
    width: 100%;
  }

  &:hover,
  &:focus {

    .arrow {
      animation: back-forth 1s linear infinite;
    }
  }

  @include breakpoint (600px) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    &-title,
    &-text {
      height: auto;
      overflow: auto;
    }

    &-info {
      width: 65%;
      padding: lineHeight(0)/2 lineHeight(0);
    }

    &-img {
      width: 35%;
      height: auto;
      margin-bottom: 0;
    }
  }

  @include breakpoint ($lg) {
    @include gallery(2);

    &-title,
    &-text {
      height: 72px;
      overflow: hidden;
    }
  }
}

//Tags slyle

.tag-cont{
  padding: lineHeight(0) 0 lineHeight(0)*2;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

.big-tag{
  background-color: $bg-light;
  display: block;
  padding: 10px 18px;
  border-radius: 18px;
  line-height: 1;
  text-align: left;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  max-width: 100%;
  flex: 1 1 auto;

  @include breakpoint ($lg) {
    max-width: 50%;
  }

  a{
    font-weight: normal;
    display: block;

    @include breakpoint ($lg) {
      display: inline;
    }

  }

}



}


// Slider styles
.slick-info {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: auto;

  .slick-dots {
    bottom: -1.5rem;
  }

  &-item-more {
    margin-top: lineHeight()/2;

    a {
      display: flex;
      justify-content: center;
      font-weight: bold;
      color: $bg-dark;

      &:visited {
        color: $bg-dark;
      }
    }
  }

  &-item-text {
    padding: lineHeight() 0;

    h3 {
      margin: 0 auto 1rem;
      padding: 0;
      text-align: center;

      a {
        margin: 0;
        padding: 0;
        font-size: typeScale(1);
        font-weight: bold;
        color: $text;
        text-align: center;

        &:visited {
          color: $text;
        }

      }

      &:hover,
      &:focus {

        a {
          color: $bg-dark;

          &:visited {
            color: $bg-dark;
          }
        }
      }
    }

    p {
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: typeScale(-1);
      text-align: justify;
    }
  }

  &-item {
    margin: lineHeight() 0;
    padding: 1rem;

    .arrow {
      margin: 0;
      bottom: -10px;
    }

    &:hover,
    &:focus {

      .arrow {
        right: -5px;
        animation: back-forth 1s linear infinite;
      }
    }
  }

  @include breakpoint($md) {
    max-width: 800px;

    &-item-more {

      a {
        justify-content: flex-start;
      }
    }

    &-item-text {

      h3 {
        text-align: left;

        a {
          font-size: typeScale(2);
          text-align: left;
        }
      }

      p {
        font-size: typeScale(0);
        text-align: justify;
      }
    }
  }

  @include breakpoint($lg) {
    max-width: $xl;

    &.slick-slider {
      margin-bottom: 0;
    }
  }

  @include breakpoint(1366px) {
    &.slick-slider {
      margin-bottom: 2rem;
    }
  }
}

// Portfolio styles
.portfolio {

  @include breakpoint ($lg) {
    .case-item {
      @include gallery(1);

      &-title,
      &-text {
        height: auto;
      }
    }
  }
}
