.partner-item {
  @include gallery(1);
  margin: lineHeight() auto;

  &-img {
    display: block;
    height: 6rem;
    width: 12rem;
    text-align: left;
  }

  &-title {
    width: 100%;
    margin-top: lineHeight();
    text-align: left;
    font: {
      size: typeScale(0);
      weight: bold;
    }

    a {
      font: {
        size: typeScale(0);
        weight: bold;
      }

      &:visited {
        color: $text;
      }

      &:hover,
      &:focus {
        color: $active;
      }
    }
  }

  &-info {
    font-size: typeScale(-1);
    text-align: justify;
  }

  @include breakpoint($md) {
    @include gallery(2);
  }

  @include breakpoint($lg) {
    &-title {
      font-size: typeScale(1);
      margin-bottom: lineHeight(0)/2;

      a {
        font-size: typeScale(1);
        }
      }

    &-info {
      font-size: typeScale(0);
    }
  }
}
