﻿// Footer styles
.footer {
  @extend %clearfix;

  position: relative;
  background-color: $bg-dark;
  background-image: $bg-gradient;
  color: #fff;
  padding-top: lineHeight();
  margin-top: lineHeight();

  &::before {
    display: block;
    position: absolute;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('/images/bg_footer.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    filter: invert(100%) brightness(200%);
  }

  &-narrow {
    display: flex;
    flex-wrap: wrap;
  }

  .wrapper-narrow {
    display: flex;
    flex-wrap: wrap;
  }

  p,
  a {
    font-size: typeScale(-1);
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 0;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  h3 {
    @include typeSetting(0);

    color: #fff;
    font-weight: bold;
    margin-top: 0;
  }

  &-part {
    width: 100%;
    padding: 0;
    justify-content: space-between;
    margin-bottom: lineHeight();
  }

  &-address {
    width: 100%;
    font-weight: normal;
    font-style: normal;
    text-align: center;
  }

  &-contacts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
    border-bottom: 1px solid rgba(white, 0.2);
    z-index: 10;
  }

  &-menu {
    display: none;
    z-index: 10;
  }

  .social-light {
    width: 100%;
    text-align: center;
    border-top: 1px solid rgba(white, 0.2);
    border-bottom: 1px solid rgba(white, 0.2);
    padding: 0 12px;

    a+a {
      margin-left: 8px;
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -1*lineHeight();
    z-index: 9;

    .button-light {
      width: 160px;
    }

    a {
      color:  $bg-dark;
      height: 40px;
      margin: lineHeight() auto;
      padding:  lineHeight()/2  lineHeight();
      display: inline-flex;

      &.feedback-button{
        padding: lineHeight()/2;
        text-align: center;
        display: block;
      }

      &:hover,
      &:focus {
        text-decoration: none;
        background-image: none;
        background-color: $bg-dark;
        color: $bg;
      }
    }
  }

  @include breakpoint($md) {
    padding-top: lineHeight(0)*2;

    &-menu {
      width: 100%;
      display: flex;
      /*margin: 0 lineHeight(0);*/
      padding: lineHeight(0)/2 0;
      border-top: 1px solid rgba(white, 0.2);
      border-bottom: 1px solid rgba(white, 0.2);

      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        margin: 0;
      }
    }

    &-part {
      width: 50%;
      justify-content: flex-start;
      padding-bottom: lineHeight(0);
      padding-right: lineHeight(0);

      h3,
      p,
      a {
        text-align: left;
      }
    }

    &-contacts {
      border-bottom: 0;
    }

    .social-light {
      width: calc(100% - 328px);
      text-align: right;
      border: 0;


    }

    &-buttons {
      margin: 0 0 -2*lineHeight(0);

      .button {
        padding: 0.75rem 1.2rem;
        white-space: nowrap;
      }
    }

    .footer-narrow {
      flex-direction: row;
    }
  }

  @include breakpoint($lg) {
    &-part {
      width: 25%;
    }
  }
}

// Copyright & design
.signature {
  width: 100%;
  position: relative;
  background-color: white;
  margin-top: lineHeight();
  padding: lineHeight()/2 0 0.25rem;

  @include breakpoint($md) {
    margin-top: lineHeight(0)*2;

    .wrapper-narrow{
      justify-content: space-between;
    }

  }
}

.copy,
.design,
.rules {

  font: {
    size: typeScale(-1);
    weight: normal;
  }

  text-align: center;
  width: 100%;
  margin: 0 auto;
  color: $text;
}

a.rules {
  color: $bg-dark;
  text-decoration: none;
  
  @include breakpoint($md) {
    width: 33%;
    text-align: center;
    margin: 0;
  }
}

.copy {
  text-align: center;

  @include breakpoint($md) {
    width: 33%;
    text-align: left;
    margin: 0;
  }
}

.design {
  text-align: center;
  margin-top: 6px;

  a {
    color: $bg-dark;
    display: inline-block;
  }

  @include breakpoint($md) {
    width: 33%;
    margin: 0;
    text-align: right;
  }
}
