﻿// Breadcrumbs
.breadcrumb {
  @extend %menu-li;

  padding: lineHeight()/2 0 lineHeight();
  margin-bottom: lineHeight();

  li {

    &.active,
    span,
    a,
    a:hover,
    a:focus,
    a:visited {
      position: relative;
      display: inline-block;
      font-size: 13px;
      color: $text-light;
      padding-right: 7px;
    }

    a,
    a:visited {
      color: $text-light;
      transition: 0.5s color;
    }

    a:hover,
    a:focus {
      span {
        color: $active;
        transition: 0.5s color;
      }
    }

    &.separator {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 10px;
        right: 5px;
        width: 0;
        height: 0;
        border-top: 3px solid transparent;
        border-left: 4px solid $active;
        border-bottom: 3px solid transparent;
      }
    }
  }
}

.bg-crumbs {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;

  &+.section>.odd-even:first-of-type>.solution {
    padding-top: 60px;
  }

  li {
    &.active,
    span,
    a,
    a:hover,
    a:focus,
    a:visited {
      position: relative;
      display: inline-block;
      font-size: 13px;
      color: #767676;
      padding-right: 7px;
    }
  }
}
