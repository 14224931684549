.service {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: lineHeight(0);

  .page {
    width: 100%;
    padding: 0 0 lineHeight();
    margin: 0 auto;
  }

  .content {
    img {
      width: 100%;
    }
  }

  .title {
    margin-top: 0;
  }

  &-nav {
    display: none;
    width: 100%;
    padding: lineHeight(0) lineHeight(0) 0;
    margin-bottom: lineHeight()*2;
    margin-top: -2*lineHeight();
    background-color: $bg-dark;
    position: absolute;
    z-index: 10;

    a {
      color: white;
      line-height: 1rem;
      font-weight: normal;
      display: inline-block;
      padding: lineHeight()/2;
      width: 100%;
    }

    .active > a,
    a:hover,
    a:focus {
      font-weight: bold;
    }

    ul {
      padding: 0;

      ul {

        a {
          padding-left: lineHeight(0);
        }
      }

    }

    li {
      border-top: 1px solid #2c75c0;
    }
  }

  &-select {
    @include dropdownMark($top: 50%, $right: 16px, $color: white, $active: white, $size: 5px, $active-top: 50%);

    display: block;
    cursor: pointer;
    width: 100%;
    margin-bottom: 2*lineHeight();
    color: white;
    font-weight: normal;
    background-color: $bg-dark;
    padding: lineHeight(0)/2;

    &::after {
      margin-top: -5px;
    }

    &.active {
      &::after {
        margin-top: -5px;
      }
    }
  }

  &-nav-wrapper {
    position: relative;
    width: 100%;
    max-width: 360px;
  }

  @include breakpoint($lg) {
    justify-content: space-between;

    .page {
      width: calc(100% - 300px);
      margin-left: 40px;
      padding-bottom: lineHeight(0)*2;
    }

    &-select {
      display: none;
    }

    &-nav {
      display: block !important;  // sass-lint:disable-line no-important
      position: relative;
      margin-top: 0;
      padding-top: 0;

      &.fixed {
        position: fixed;
        top: 96px;
        width: 260px;
      }

      &.fixed-bottom {
        position: absolute;
        bottom: 0;
      }
    }

    &-nav-wrapper {
      width: 260px;
    }
  }

  @include breakpoint($xl) {
    .page {
      width: calc(100% - 360px);
      margin-left: 60px;
    }

    &-nav.fixed,
    &-nav-wrapper {
      width: 300px;
    }
  }
}
