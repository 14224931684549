@font-face {
  font-family: 'DINNext';
  src: url('/fonts/DINNextCYR-Bold.eot');
  src: url('/fonts/DINNextCYR-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/DINNextCYR-Bold.woff2') format('woff2'),
      url('/fonts/DINNextCYR-Bold.woff') format('woff'),
      url('/fonts/DINNextCYR-Bold.ttf') format('truetype'),
      url('/fonts/DINNextCYR-Bold.svg#DINNextCYR-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DINNext';
  src: url('/fonts/DINNextCYR-Regular.eot');
  src: url('/fonts/DINNextCYR-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/DINNextCYR-Regular.woff2') format('woff2'),
      url('/fonts/DINNextCYR-Regular.woff') format('woff'),
      url('/fonts/DINNextCYR-Regular.ttf') format('truetype'),
      url('/fonts/DINNextCYR-Regular.svg#DINNextCYR-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINNext';
  src: url('/fonts/DINNextCYR-BoldItalic.eot');
  src: url('/fonts/DINNextCYR-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/DINNextCYR-BoldItalic.woff2') format('woff2'),
      url('/fonts/DINNextCYR-BoldItalic.woff') format('woff'),
      url('/fonts/DINNextCYR-BoldItalic.ttf') format('truetype'),
      url('/fonts/DINNextCYR-BoldItalic.svg#DINNextCYR-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DINNext';
  src: url('/fonts/DINNextCYR-Italic.eot');
  src: url('/fonts/DINNextCYR-Italic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/DINNextCYR-Italic.woff2') format('woff2'),
      url('/fonts/DINNextCYR-Italic.woff') format('woff'),
      url('/fonts/DINNextCYR-Italic.ttf') format('truetype'),
      url('/fonts/DINNextCYR-Italic.svg#DINNextCYR-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}