.presentation-links {
  margin: lineHeight() auto lineHeight()*3;
  padding: lineHeight()/2 0;
  border-top: 1px solid $line;
  border-bottom: 1px solid $line;
  text-align: center;

  a {
    margin: lineHeight()/2;
  }
}

